import styled, { css } from 'styled-components';

export const ScListingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScListingRow = styled.div<{ $isBold: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 0.825em;
  line-height: 2.125em;
  font-weight: ${({ $isBold }) => ($isBold ? '500' : '400')};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (aspect-ratio: 64 / 27) {
    margin-bottom: 0.625em;
  }
`;

const listingCellStyles = css`
  flex: 1;
  width: 50%;
  margin-left: 30px;
  color: #464646;

  &:first-of-type {
    margin-left: 0;
  }
`;

export const ScListingCellKey = styled.div`
  ${listingCellStyles}
  font-size: 1.5em;
  text-transform: capitalize;
  word-break: break-word;
`;

export const ScListingCellValue = styled.div`
  ${listingCellStyles}
  font-size: 1.25em;
  text-align: right;
`;

export const ScListingColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
