import styled from 'styled-components';
import Header from '../Header';

export const ScScreenSaverFrame = styled.iframe`
  height: 100vh;
  width: 100vw;
`;

export const ScLayout = styled.div`
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
`;

export const ScLayoutContent = styled.div`
  height: calc(100vh - 4.375em);
  display: flex;
  align-items: center;
  padding: 1.5em;
  padding-top: 0;

  @media (aspect-ratio: 64 / 27) {
    height: calc(100vh - 3.75em);
    padding-bottom: 0.75em;
  }
`;

export const ScLayoutContentWrapper = styled.div`
  flex: 1;
`;

export const ScBackgroundHeader = styled(Header)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;
