import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { dispatch, streamingActions } from '../store';
import { ConnectionStatus, Slots } from '../types/streaming';
import createConnection from 'utils/createConnection';
import { StorageKeys } from 'types/common';

const connectionInstance = createConnection();

interface ContextValue {
  sendMessage(params: unknown): void;
  setup(): void;
  end(): void;
  connectionStatus: ConnectionStatus;
}

const terminalStatusToConnectionStatus = {
  busy: ConnectionStatus.Busy,
  offline: ConnectionStatus.Offline,
  vacant: ConnectionStatus.Vacant,
};

const StreamingContext = React.createContext<ContextValue | undefined>(undefined);

const StreamingProvider = props => {
  const pingRef = useRef<NodeJS.Timer | null>(null);

  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
    ConnectionStatus.Offline,
  );
  const navigate = useNavigate();

  const searchString = window.location.search.replace('?', '');
  const query: Record<string, string> = {};
  searchString.split('&').forEach(searchItem => {
    const [key, value] = searchItem.split('=');
    query[key] = value;
  });
  const { host } = query;

  const sendMessage = useCallback(
    params => {
      const { type, data } = params;

      const connection = connectionInstance.get();

      connection?.send(
        JSON.stringify({
          type,
          host,
          data,
        }),
      );
    },
    [host],
  );

  const handleMessage = useCallback(
    message => {
      const parsedMessage = JSON.parse(message.data);
      const { type, data, errors } = parsedMessage;

      const { connectionId } = parsedMessage;
      if (connectionId) {
        try {
          const currentConnectionId = localStorage.getItem(StorageKeys.connectionId);
          if (!currentConnectionId) {
            localStorage.setItem(StorageKeys.connectionId, connectionId);
          }
        } catch (err) {
          //
        }
      }

      if (type === 'initialize' && data) {
        const newConnectionStatus = terminalStatusToConnectionStatus[data.status];
        setConnectionStatus(newConnectionStatus ?? ConnectionStatus.Offline);
        dispatch(streamingActions.setTerminal(data));

        connectionInstance.send({ type: 'getCurrentState' });
      }

      if (type === 'changeScreen') {
        dispatch(streamingActions.setScreen(data));
      }

      if (type === 'getCurrentState') {
        const newConnectionStatus = terminalStatusToConnectionStatus[data.status];
        setConnectionStatus(newConnectionStatus ?? ConnectionStatus.Offline);
        dispatch(streamingActions.setTerminal(data));

        // handles setting up screen on initialization
        if (data.screen) {
          dispatch(streamingActions.setScreen(data.screen));
        }

        if (pingRef.current) {
          clearInterval(pingRef.current);
        }

        if (newConnectionStatus === ConnectionStatus.Busy) {
          const pingInterval = setInterval(() => {
            if (!connectionInstance.isReady()) {
              connectionInstance.init({ onmessage: handleMessage }).then(() => {
                connectionInstance.send({ type: 'ping', host });
              });
              return;
            }

            connectionInstance.send({ type: 'ping', host });
          }, 5000);

          pingRef.current = pingInterval;
        }

        if (newConnectionStatus === ConnectionStatus.Offline) {
          connectionInstance.get()?.close(1000);
        }
      }

      if (type === 'receiveSlotChange') {
        if (data?.data) {
          const name = data.name as keyof Slots;
          dispatch(streamingActions.setSlots({ [name]: data.data }));
        }
      }

      if (type === 'ping') {
        if (
          'isStreamBroken' in data &&
          !data.isStreamBroken &&
          !connectionInstance.isReady()
        ) {
          connectionInstance.close();
          connectionInstance.init({ onmessage: handleMessage });
        }

        if (data?.isStreamBroken && pingRef.current) {
          clearInterval(pingRef.current);
        }
      }

      if (type === 'logout' && !errors.length) {
        connectionInstance.get()?.close(1000);
      }
    },
    [host],
  );

  const setup = useCallback(async () => {
    const connection = await connectionInstance.init({ onmessage: handleMessage });

    connection.onclose = event => {
      connectionInstance.close();
      setConnectionStatus(ConnectionStatus.Offline);

      if (event.code === 1000) {
        localStorage.removeItem(StorageKeys.connectionId);
        dispatch(streamingActions.setTerminal(null));
        dispatch(streamingActions.setScreen(null));
        dispatch(streamingActions.setSlots({}));
        navigate(`/?host=${host}`);
        if (pingRef.current) {
          clearInterval(pingRef.current);
        }
      }
    };
  }, [handleMessage, host, navigate]);

  const end = useCallback(() => {
    connectionInstance.close();
  }, []);

  const value = useMemo(
    () => ({
      sendMessage,
      setup,
      end,
      connectionStatus,
    }),
    [sendMessage, setup, end, connectionStatus],
  );

  return <StreamingContext.Provider value={value} {...props} />;
};

const useStreaming = (): ContextValue => {
  const context = useContext(StreamingContext);

  if (context === undefined) {
    throw new Error('useStreaming must be used within an StreamingProvider');
  }

  return context;
};

export { StreamingProvider, useStreaming };
