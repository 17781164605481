import styled from 'styled-components';

export const ScScreenSaverWrapper = styled.div<{ $withBackdrop: boolean }>`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  ${({ $withBackdrop }) =>
    $withBackdrop
      ? `
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  `
      : ''}
`;

export const ScScreenSaverVideo = styled.video`
  height: 100vh;
  width: 100vw;
  object-fit: cover;
`;
