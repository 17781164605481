import styled from 'styled-components';

export const NoDataWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 8px 0px rgba(230, 230, 230, 0.26),
    0px 0px 4px 0px rgba(230, 230, 230, 0.2);
  margin: auto;
`;

export const NoDataBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
          27.54% 23.05% at 5.8% 98.63%,
          rgba(87, 174, 255, 0.26) 0%,
          rgba(255, 117, 244, 0) 100%,
          rgba(117, 222, 255, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          82.97% 24.11% at 95.29% 46.29%,
          rgba(87, 174, 255, 0.49) 0%,
          rgba(255, 117, 244, 0) 100%,
          rgba(117, 222, 255, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      linear-gradient(0deg, rgba(230, 230, 230, 0.5), rgba(230, 230, 230, 0.5)),
      radial-gradient(
          46.74% 45.51% at 8.7% 4.49%,
          rgba(255, 87, 242, 0.49) 0%,
          rgba(255, 117, 244, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          35.51% 31.45% at 100% 99.02%,
          rgba(255, 87, 242, 0.49) 0%,
          rgba(255, 117, 244, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      linear-gradient(0deg, #ffffff, #ffffff);
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: white;
  }
`;

export const NoDataContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
