import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import SlotTextGroup from '../../SlotTextGroup';
import Listing from '../../Listing';
import {
  ScCatalogPriceWrapper,
  ScDownloadDocumentWrapper,
  ScHeader,
  ScHorizontalLine,
  ScListingActionsWrapper,
  ScSummaryListingWrapper,
} from './Summary.styles';

const namedKeys: Record<string, string> = {
  SUMMARY_NATIONAL_PROMOTIONS: 'SUMMARY_NATIONAL_PROMOTIONS',
  SUMMARY_LOCAL_PROMOTIONS: 'SUMMARY_LOCAL_PROMOTIONS',
  DELTA: 'SUMMARY_DELTA_PROMOTION',
  DELIV: 'SUMMARY_DELIV_PROMOTION',
};

const keys = [
  'SUMMARY_BASE_PRICE',
  'SUMMARY_OPTIONS_TOTAL',
  'SUMMARY_PACKAGES_TOTAL',
  'SUMMARY_ACCESSORIES_TOTAL',
  'SUMMARY_CATALOG_SUBTOTAL',
];

type Props = {
  data: {
    documentSrc: string | undefined;
    configurationModelName: string | undefined;
    fields: [string, string][];
  };
  isPublished: boolean | undefined;
};

const Summary: FC<Props> = ({ data, isPublished }) => {
  const { t } = useTranslation();

  const { documentSrc, configurationModelName, fields } = data;

  const rows: [string, string, boolean][] = useMemo(() => {
    return fields.map((field, index) => {
      if (index === fields.length - 3) {
        return [t('SUMMARY_SUBTOTAL'), field[1], true];
      }
      if (index === fields.length - 2) {
        return [t('SUMMARY_TRADEIN'), field[1], false];
      }
      if (index === fields.length - 1) {
        return [t('SUMMARY_OVERALL_TOTAL'), field[1], true];
      }

      if (namedKeys[field[0]]) {
        return [t(namedKeys[field[0]]), field[1], false];
      }

      if (keys[index]) {
        return [t(keys[index]), field[1], index === 4];
      }

      return [...field, false];
    });
  }, [fields, t]);

  const mappedRows = useMemo(() => {
    return {
      basePrice: rows[0],
      options: rows[1],
      packages: rows[2],
      accessories: rows[3],
      catalogPrice: rows[4],
      actions: rows.slice(5, -3),
      subtotal: rows[rows.length - 3],
      tradeIn: rows[rows.length - 2],
      total: rows[rows.length - 1],
    };
  }, [rows]);

  const tradeInValue = useMemo(() => {
    return parseFloat(mappedRows.tradeIn?.[1].replace(',', '.').replace(/[^0-9.]/g, ''));
  }, [mappedRows.tradeIn]);

  return (
    <>
      <ScHeader>
        <SlotTextGroup title={t('SUMMARY_TITLE')} withBorder={false}>
          {configurationModelName}
        </SlotTextGroup>
      </ScHeader>

      <ScSummaryListingWrapper>
        <Listing
          data={[
            mappedRows.basePrice,
            mappedRows.options,
            mappedRows.packages,
            mappedRows.accessories,
          ]}
        />
        <ScHorizontalLine />
        <ScCatalogPriceWrapper>
          <Listing data={[mappedRows.catalogPrice]} />
        </ScCatalogPriceWrapper>
        {mappedRows.actions.length > 0 && (
          <ScListingActionsWrapper>
            <Listing data={mappedRows.actions} />
          </ScListingActionsWrapper>
        )}

        {Boolean(tradeInValue) && (
          <>
            <ScHorizontalLine />
            <Listing data={[mappedRows.subtotal]} />

            <ScHorizontalLine />
            <Listing data={[mappedRows.tradeIn]} />
          </>
        )}

        <ScHorizontalLine />
        <Listing data={[mappedRows.total]} />
      </ScSummaryListingWrapper>
      {isPublished && documentSrc && (
        <ScDownloadDocumentWrapper>
          <Button href={documentSrc} target="_blank">
            {t('SUMMARY_DOCUMENT_DOWNLOAD')}
          </Button>
        </ScDownloadDocumentWrapper>
      )}
    </>
  );
};

export default Summary;
