import Button from 'react-bootstrap/Button';
import styled, { css } from 'styled-components';

const common = css`
  width: 100%;
  padding: 0.65em 0;
  border-radius: 0;
  font-size: 1em;
`;

export const ScButtonPrimary = styled(Button)`
  ${common}
  background-color: #2a609d !important;
`;

export const ScButtonLink = styled(Button)`
  ${common}
  background-color: transparent;
  padding: 0;
  width: min-content;
  text-decoration: none;
  color: black;
  box-shadow: none;
  transition: none;
  border: 0;
  outline: none;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible,
  &:active:focus {
    text-decoration: underline;
    color: inherit;
    outline: none;
    border: none;
    box-shadow: none;
  }
`;
