import { FC } from 'react';
import Selection from './MainPreview/Selection';
import PriceList from './MainPreview/PriceList';
import Model from './MainPreview/Model';
import Summary from './MainPreview/Summary';
import { ScMainPreviewWrapper } from './MainPreview.styles';
import { Slots } from 'types/streaming';

type Props = {
  slots: Slots;
  screen: string | null;
};

const MainPreview: FC<Props> = ({ slots, screen }) => {
  const { isPublished } = slots.global;

  return (
    <ScMainPreviewWrapper>
      {isPublished ? (
        <>
          <Summary previewImages={slots.summary.previewImages} />
        </>
      ) : (
        <>
          {screen === 'selection' && (
            <Selection
              mainPreviewImages={slots.selection.mainImages}
              optionsPreviewImages={slots.selection.optionsImages}
              categoryImages={slots.selection.categoryImages}
            />
          )}
          {screen === 'priceList' && <PriceList url={slots.priceList.priceListImage} />}
          {screen === 'model' && (
            <Model url={slots.model.previewImage || slots.priceList.priceListImage} />
          )}
          {screen === 'summary' && (
            <Summary previewImages={slots.summary.previewImages} />
          )}
        </>
      )}
    </ScMainPreviewWrapper>
  );
};

export default MainPreview;
