import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { ScInput } from './Input.styles';

type Props = {
  id?: string;
  label?: string;
  type: string;
  placeholder?: string | null;
  onChange: React.ComponentProps<typeof Form.Control>['onChange'];
  value: string;
};

const Input: FC<Props> = ({ label, type, id, placeholder, onChange, value }) => {
  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <ScInput
        type={type}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </>
  );
};

export default Input;
