import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import Input from '../components/Input';
import {
  ScPageWrapper,
  ScFormWrapper,
  ScInputWrapper,
  ScImageWrapper,
} from './Login.styles';
import { ReactComponent as VolvoLogo } from '../images/volvo-logo.svg';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [customerId, setCustomerId] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCustomerId(newValue);
  }, []);

  const handleConnect = useCallback(() => {
    if (!customerId) return;
    const host = new URLSearchParams(location.search).get('host');
    navigate(`/page?customer_id=${customerId}&host=${host}`);
  }, [navigate, customerId, location]);

  return (
    <>
      <Helmet>
        <title>{t('PAGE_TITLE')}</title>
      </Helmet>
      <ScPageWrapper>
        <ScFormWrapper>
          <ScImageWrapper>
            <VolvoLogo />
          </ScImageWrapper>
          <ScInputWrapper>
            <Input
              onChange={handleChange}
              value={customerId}
              type="text"
              placeholder={t('LOGIN_PLACEHOLDER')}
            />
          </ScInputWrapper>
          <Button type="button" onClick={handleConnect} disabled={!customerId}>
            {t('LOGIN')}
          </Button>
        </ScFormWrapper>
      </ScPageWrapper>
    </>
  );
};

export default Login;
