import { FC, useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  ScMainPreviewCarouselWrapper,
  ScNoDataOverlay,
  ScSelectionWrapper,
  ScOptionsSlotsWrapper,
  ScOffcanvas,
  ScOffcanvasBody,
  ScImageSlot,
} from './Selection.styles';
import { ScCaption, ScSlotWrapper } from '../styles';
import { Image as ImageData } from '../../../types/streaming';
import Loader from '../../Loader';
import { ReactComponent as BucketIcon } from '../../../images/bucket.svg';
import { ReactComponent as TiresIcon } from '../../../images/tires.svg';
import { ReactComponent as VolvoLogo } from '../../../images/volvo-logo.svg';
import NoData from '../../NoData';
import VerticalCarousel from '../VerticalCarousel';
import SelectionCarousel from './Selection/Carousel';
import ImageCard from 'components/ImageCard';
import Caption from 'components/Caption';

type Props = {
  mainPreviewImages: ImageData[];
  optionsPreviewImages: (ImageData & { position: number })[];
  categoryImages: ImageData[];
};

const indexToErrorIcon: Record<number, typeof BucketIcon> = {
  0: BucketIcon,
  1: VolvoLogo,
  2: VolvoLogo,
  3: TiresIcon,
};

const indexToDefaultDescription: Record<number, string> = {
  0: 'SELECTION_COLOR_DESCRIPTION',
  1: 'SELECTION_CATEGORY_DESCRIPTION',
  2: 'SELECTION_INTERIOR_DESCRIPTION',
  3: 'SELECTION_RIMS_DESCRIPTION',
};

const getDefaultDescription = (index: number, t: TFunction): string => {
  return t(indexToDefaultDescription[index]);
};

const Selection: FC<Props> = ({
  mainPreviewImages,
  optionsPreviewImages,
  categoryImages,
}) => {
  const { t } = useTranslation();
  const offcanvasBodyRef = useRef<HTMLDivElement | null>(null);

  const [loadedMainPreviewImages, setLoadedMainPreviewImages] = useState<
    Record<number, true>
  >({});

  const handleMainPreviewImageLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const target = event.target as HTMLImageElement;

      setLoadedMainPreviewImages(prevState => ({
        ...prevState,
        [Number(target.id)]: true,
      }));
    },
    [],
  );

  const getCaption = useCallback(
    (image: ImageData, index: number) => {
      return image?.description || getDefaultDescription(index, t);
    },
    [t],
  );

  useEffect(() => {
    offcanvasBodyRef?.current?.scrollTo({ top: 0 });
  }, [categoryImages]);

  const hasLoadedMainPreview =
    Object.keys(loadedMainPreviewImages).length === mainPreviewImages.length;

  const optionsPreviewImagesHash = useMemo<Record<number, ImageData>>(() => {
    return optionsPreviewImages.reduce((res, cur) => {
      res[cur.position] = cur;
      return res;
    }, {});
  }, [optionsPreviewImages]);

  return (
    <ScSelectionWrapper>
      <ScMainPreviewCarouselWrapper>
        {!hasLoadedMainPreview && (
          <ScNoDataOverlay>
            <NoData>
              <Loader />
            </NoData>
          </ScNoDataOverlay>
        )}
        <SelectionCarousel
          mainPreviewImages={mainPreviewImages}
          hasLoadedMainPreview={hasLoadedMainPreview}
          handleMainPreviewImageLoad={handleMainPreviewImageLoad}
        />
      </ScMainPreviewCarouselWrapper>
      <ScOptionsSlotsWrapper direction="horizontal" gap={3}>
        {[...Array(4).keys()].map(index => {
          const image = optionsPreviewImagesHash[index];

          return (
            <ScSlotWrapper key={index}>
              <ScImageSlot
                image={image}
                errorIcon={indexToErrorIcon[index]}
                defaultDescription={getDefaultDescription(index, t)}
              />
              <Caption title={getCaption(image, index)}>
                {getCaption(image, index)}
              </Caption>
            </ScSlotWrapper>
          );
        })}
      </ScOptionsSlotsWrapper>
      <ScOffcanvas show={!!categoryImages.length} placement="end" backdrop={false}>
        <ScOffcanvasBody id="category-view" ref={offcanvasBodyRef}>
          <VerticalCarousel itemsToShow={4} scrollContainerId="category-view">
            {categoryImages.map((image, index) => {
              const displayName = image.description;

              return (
                <ScSlotWrapper key={index}>
                  <ImageCard
                    errorIcon={VolvoLogo}
                    url={image.url}
                    name={displayName}
                    key={displayName}
                    defaultDescription={t('SELECTION_CATEGORY_DEFAULT_DESCRIPTION')}
                  >
                    <ScCaption $truncated={false}>{displayName}</ScCaption>
                  </ImageCard>
                </ScSlotWrapper>
              );
            })}
          </VerticalCarousel>
        </ScOffcanvasBody>
      </ScOffcanvas>
    </ScSelectionWrapper>
  );
};

export default Selection;
