import styled from 'styled-components';
import { Stack } from 'react-bootstrap';

export const ScPreviewWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  padding-top: 1.5em;

  @media (aspect-ratio: 64 / 27) {
    padding-top: 0.75em;
  }
`;

export const ScMainPreviewWrapper = styled.div<{ $maxSpace: boolean }>`
  flex: 3;
  max-width: ${({ $maxSpace }) => ($maxSpace ? '80vw' : '60vw')};
  height: 100%;
`;

export const ScDetailedPreviewWrapper = styled.div`
  flex: 1;
  min-width: min-content;
  height: 100%;
`;

export const ScPreviewStack = styled(Stack)<{ $maxSpace: boolean }>`
  ${({ $maxSpace }) =>
    $maxSpace
      ? `
    gap: 0;
    margin: auto;
      `
      : `
    flex: 1;
    gap: 2.75em;
`}
`;
