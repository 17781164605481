import styled from 'styled-components';

export const ScBadge = styled.div`
  width: max-content;
  text-transform: capitalize;
  border-radius: 4px;
  padding: 0.5em;
  background-color: #c3d1e0;
  font-weight: 500;
  font-size: 1em;
  color: #191d32;
`;
