import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import { ScSlotWrapper } from '../styles';

export const NoContent = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 1.375em;
  color: #888b8d;
  text-align: center;
  margin: 0;
`;

export const ScModal = styled(Modal)`
  .modal-content {
    position: relative;
    border-radius: 1px;
  }
`;

export const ScClose = styled(XLg)`
  position: absolute;
  top: 0;
  right: -1.5em;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
`;

export const ScDynamicSlotWrapper = styled(ScSlotWrapper)`
  cursor: pointer;
`;

export const ScStaticPackagePreviewWrapper = styled.div`
  .rec-item-wrapper {
    padding: 0 !important;
    padding-bottom: 1em !important;
  }
`;

export const ScOptionsList = styled.ul`
  font-weight: 400;
  font-size: 1em;
  color: #464646;
`;

export const ScOptionItem = styled.li``;
