import { FC } from 'react';
import { ScScreenSaverVideo, ScScreenSaverWrapper } from './Backgrounnd.styles';
import { ScLayout, ScLayoutContentWrapper } from './styles';
import { ReactComponent as VolvoLogo } from '../../images/volvo-logo.svg';

type Props = {
  standbyView: string | null;
  header: React.ReactNode;
};

const Background: FC<Props> = ({ standbyView, header }) => {
  return (
    <ScScreenSaverWrapper $withBackdrop={standbyView === 'video'}>
      {header}
      {standbyView === 'video' ? (
        <ScScreenSaverVideo
          autoPlay
          muted
          loop
          controls={false}
          title="The new Volvo C40 Recharge"
        >
          <source
            src="https://xdms-1.s3.eu-west-1.amazonaws.com/streaming/screenSaver_compressed.mp4"
            type="video/mp4"
          />
        </ScScreenSaverVideo>
      ) : (
        <ScLayout>
          <ScLayoutContentWrapper>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <div
                style={{
                  width: '12.5em',
                  height: '12.5em',
                }}
              >
                <VolvoLogo />
              </div>
            </div>
          </ScLayoutContentWrapper>
        </ScLayout>
      )}
    </ScScreenSaverWrapper>
  );
};

export default Background;
