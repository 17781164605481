import styled from 'styled-components';

export const ScHeader = styled.div`
  margin-bottom: 2.25em;
`;

export const ScListingWrapper = styled.div`
  margin-bottom: 3.125em;
`;

export const ScSummaryListingWrapper = styled.div``;

export const ScDownloadDocumentWrapper = styled.div`
  padding: 0 5.75em;
`;

export const ScHorizontalLine = styled.span`
  height: 1px;
  margin: 0.5em 0;
  width: 100%;
  display: inline-block;
  background: rgba(152, 152, 152, 0.25);
`;

export const ScCatalogPriceWrapper = styled.div``;

export const ScListingActionsWrapper = styled.div`
  margin-top: 2.25em;
`;
