import { FC } from 'react';

import Model from './DetailedPreview/Model';
import PriceList from './DetailedPreview/PriceList';
import Selection from './DetailedPreview/Selection';
import Summary from './DetailedPreview/Summary';
import { ScDetailedPreviewWrapper } from './DetailedPreview.styles';
import { OptionType, PreviewImage, Slots, Terminal } from '../../types/streaming';

type Props = {
  slots: Slots;
  screen: string | null;
  terminal: Terminal | null;
  previewImages: PreviewImage[];
  optionType: OptionType | null;
};

const DetailedPreview: FC<Props> = ({
  slots,
  screen,
  terminal,
  previewImages,
  optionType,
}) => {
  // @todo: do not get slots as props
  const { isPublished } = slots.global;

  return (
    <ScDetailedPreviewWrapper id="detailed-preview">
      {(isPublished || screen === 'summary') && (
        <Summary data={slots.summary} isPublished={isPublished} />
      )}
      {screen === 'selection' && (
        <Selection
          previewImages={previewImages}
          type={terminal?.flags?.type ?? 'static'}
          optionType={optionType}
        />
      )}
      {screen === 'model' && (
        <Model
          modelYear={slots.model.modelYear}
          selectedModel={slots.model.selectedModel}
        />
      )}
      {screen === 'priceList' && (
        <PriceList
          name={slots.priceList.priceListName}
          description={slots.priceList.priceListDescription}
          modelYear={slots.priceList.modelYear}
          companyName={slots.priceList.companyName}
        />
      )}
    </ScDetailedPreviewWrapper>
  );
};

export default DetailedPreview;
