import Caption from '../Caption';
import styled from 'styled-components';
import Image from '../Image';

export const ScPreviewImage = styled(Image)`
  height: 100%;
  width: 100%;
  display: inline-block;
`;

export const ScDotItem = styled.div<{ $isActive: boolean; $isLast: boolean }>`
  margin-right: ${({ $isLast }) => ($isLast ? '0' : '20px')};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $isActive }) => ($isActive ? '#888B8D' : '#E1DFDD')};
  cursor: pointer;
`;

export const ScSlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;

  @media (aspect-ratio: 64 / 27) {
    height: 100%;
  }
`;

export const ScCaption = styled(Caption)`
  margin-top: 0.75em;
`;

export const ScText = styled.div`
  font-weight: 400;
  font-size: 1em;
  color: #464646;
  margin-bottom: 0.5em;
  white-space: pre-line;
`;
