import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import {
  canAutoPlayCarPreview,
  getActionName,
  getCarPreviewImages,
  getPreviewImages,
} from '../utils';
import { ApplicationState, StreamingState } from '../types';
import { get } from 'utils';
import i18n from '../../i18n';

const NAME = 'streaming';

const initialState: StreamingState = {
  terminal: null,
  slots: {
    selection: {
      categoryImages: [],
      mainImages: [],
      optionsImages: [],
      previewImages: [],
      optionType: null,
      packageDetails: null,
    },
    summary: {
      previewImages: [],
      documentSrc: '',
      configurationModelName: '',
      fields: [],
      imageSrc: '',
    },
    global: {
      isPublished: false,
      languageCode: null,
      modelName: null,
      configurationNumber: null,
      carPreviewImages: [],
      selectedRecordsImages: [],
      canAutoPlayCarPreview: false,
      scrollYPos: null,
    },
    priceList: {
      priceListName: '',
      priceListImage: '',
      priceListDescription: '',
      modelYear: '',
      companyName: '',
    },
    model: {
      modelYear: '',
      previewImage: null,
      selectedModel: [],
    },
  },
  screen: null,
};

const actions = {
  setTerminal: createAction<StreamingState['terminal']>(
    getActionName(NAME, 'SET_TERMINAL'),
  ),
  setSlots: createAction<Partial<StreamingState['slots']>>(
    getActionName(NAME, 'SET_SLOTS'),
  ),
  setScreen: createAction<StreamingState['screen']>(getActionName(NAME, 'SET_SCREEN')),
  setFocusedCarImageIndex: createAction<number>(
    getActionName(NAME, 'SET_FOCUSED_CAR_IMAGE_INDEX'),
  ),
};

const reducer = createReducer<StreamingState>(initialState, builder => {
  builder.addCase(actions.setTerminal, (state, action) => ({
    ...state,
    terminal: action.payload,
  }));
  builder.addCase(actions.setSlots, (state, action) => {
    const language =
      action.payload?.global?.languageCode ?? state.slots.global.languageCode;

    if (language) {
      i18n.changeLanguage(language);
    }

    return {
      ...state,
      slots: {
        ...state.slots,
        ...action.payload,
        global: {
          ...get(state, 'slots.global'),
          ...get(action, 'payload.global', {}),
          languageCode:
            get(action, 'payload.global')?.languageCode ??
            get(state, 'slots.global').languageCode,
          carPreviewImages: getCarPreviewImages(
            get(state, 'slots.global.carPreviewImages', []),
            get(action, 'payload.global.carPreviewImages', []),
            !!action.payload.global,
          ),
          canAutoPlayCarPreview: canAutoPlayCarPreview(
            get(action, 'payload.global.carPreviewImages', []),
          ),
        },
        selection: {
          ...get(state, 'slots.selection'),
          ...get(action, 'payload.selection', {}),
          previewImages: action.payload.selection
            ? getPreviewImages(
                state.slots.selection.previewImages,
                action.payload?.selection?.previewImages ?? [],
              )
            : state.slots.selection.previewImages,
        },
      },
    };
  });
  builder.addCase(actions.setScreen, (state, action) => {
    return {
      ...state,
      screen: action.payload,
    };
  });
  builder.addCase(actions.setFocusedCarImageIndex, (state, action) => {
    return {
      ...state,
      slots: {
        ...state.slots,
        global: {
          ...state.slots.global,
          carPreviewImages: state.slots.global.carPreviewImages.map((image, index) => ({
            ...image,
            focused: index === action.payload,
          })),
        },
      },
    };
  });
});

const selectors = {
  getTerminal: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.terminal,
  ),
  getSlots: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.slots,
  ),
  getScreen: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.screen,
  ),
  getCarPreviewImages: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.slots?.global.carPreviewImages ?? [],
  ),
  getSelectedRecordsImages: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.slots?.global.selectedRecordsImages ?? [],
  ),
  getLanguageCode: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.slots.global.languageCode,
  ),
  getPackageDetails: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.slots.selection.packageDetails,
  ),
  getFocusedCarImageIndex: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.slots.global.carPreviewImages.findIndex(image => image.focused),
  ),
  canAutoPlayCarPreview: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.slots.global.canAutoPlayCarPreview,
  ),
};

export {
  actions as streamingActions,
  reducer as streamingReducer,
  selectors as streamingSelectors,
};
