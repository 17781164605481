import { FC, useCallback, useEffect, useState } from 'react';
import Image from './Image';
import { Image as ImageData } from '../types/streaming';
import {
  ScImageSlotWrapper,
  ScImageSlotErrorWrapper,
  ScNoDataWrapper,
} from './ImageSlot.styles';
import Loader from './Loader';
import NoData from './NoData';

type Props = {
  className?: string;
  image: Pick<ImageData, 'url' | 'description'> | undefined;
  errorIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> & {
    title?: string | undefined;
  };
  defaultDescription: string;
};

const ImageSlot: FC<Props> = ({
  className,
  image,
  errorIcon: ErrorIcon,
  defaultDescription,
}) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(!image?.url);
  const [isError, setIsError] = useState<boolean>(false);

  const handleImageLoad = useCallback(() => {
    setHasLoaded(true);
  }, []);

  const handleImageError = useCallback(() => {
    setHasLoaded(true);
    setIsError(true);
  }, []);

  useEffect(() => {
    if (image) {
      setHasLoaded(true);
      setIsError(false);
    } else {
      handleImageError();
    }
  }, [image, handleImageError]);

  return (
    <ScImageSlotWrapper className={className}>
      {!hasLoaded && (
        <NoData>
          <Loader />
        </NoData>
      )}
      {isError && (
        <ScNoDataWrapper>
          <NoData>
            <ScImageSlotErrorWrapper>
              <ErrorIcon color="#E1DFDD" />
            </ScImageSlotErrorWrapper>
          </NoData>
        </ScNoDataWrapper>
      )}
      {image && !isError && (
        <Image
          src={image.url}
          alt={image.description ?? defaultDescription}
          title={image.description ?? defaultDescription}
          height="100%"
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      )}
    </ScImageSlotWrapper>
  );
};

export default ImageSlot;
