import React, { FC } from 'react';
import { ScImage, ScImageWrapper, ScNoImage } from './Image.styles';
import { ImageProps } from 'react-bootstrap/Image';

type Props = Omit<ImageProps, 'url'> & {
  url?: string;
  index?: number;
  opacity?: string | number;
};

const Image: FC<Props> = ({ index, opacity, src, ...props }) => {
  return (
    <ScImageWrapper $center={!src}>
      {src ? (
        <ScImage {...props} src={src} $height={props.height ?? 'auto'} />
      ) : (
        <ScNoImage $height={props.height ?? '50%'} />
      )}
    </ScImageWrapper>
  );
};

export default React.memo(
  Image,
  (prevProps, nextProps) => prevProps.src === nextProps.src,
);
