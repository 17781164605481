import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  ScDotItemWrapper,
  ScNoImageIconDot,
  ScNoImageIconMain,
  ScPreviewCarPreviewWrapper,
} from './CarPreview.styles';
import Modal from 'components/Modal';
import Image from 'components/Image';
import AliceCarousel from 'react-alice-carousel';
import { Image as ImageType } from 'types/streaming';
import { useSelector } from 'react-redux';
import { dispatch, streamingActions, streamingSelectors } from 'store';

type Props = {
  carPreviewImages: ImageType[];
};

const CarPreview: FC<Props> = ({ carPreviewImages }) => {
  const autoPlayRef = useRef<NodeJS.Timer | null>(null);
  const focusedImageIndex = useSelector(streamingSelectors.getFocusedCarImageIndex);
  const canAutoPlayCarPreview = useSelector(streamingSelectors.canAutoPlayCarPreview);
  const numberOfPreviewImages = carPreviewImages.length;

  const [loadedCarPreviewImages, setLoadedCarPreviewImages] = useState<
    Record<number, boolean>
  >({});

  const handleCarPreviewImageLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const target = event.target as HTMLImageElement;

      setLoadedCarPreviewImages(prevState => ({
        ...prevState,
        [Number(target.id)]: true,
      }));
    },
    [],
  );

  const handleCarPreviewImageError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const target = event.target as HTMLImageElement;

      setLoadedCarPreviewImages(prevState => ({
        ...prevState,
        [Number(target.id)]: false,
      }));
    },
    [],
  );

  const hasLoadedCarPreview =
    Object.keys(loadedCarPreviewImages).length === carPreviewImages.length;

  useEffect(() => {
    autoPlayRef.current = setInterval(() => {
      dispatch(
        streamingActions.setFocusedCarImageIndex(
          (focusedImageIndex + 1) % numberOfPreviewImages,
        ),
      );
    }, 3000);

    if (!canAutoPlayCarPreview) {
      clearInterval(autoPlayRef.current);
    }

    return () => {
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
    };
  }, [focusedImageIndex, canAutoPlayCarPreview, numberOfPreviewImages]);

  return (
    <Modal show fullscreen>
      <ScPreviewCarPreviewWrapper $width={carPreviewImages.length === 1 ? '80%' : '100%'}>
        <AliceCarousel
          renderKey={Number(canAutoPlayCarPreview)}
          items={carPreviewImages.map((image, index) => {
            if (hasLoadedCarPreview && !loadedCarPreviewImages[index]) {
              return <ScNoImageIconMain key={index} />;
            }

            return (
              <Image
                id={String(index)}
                key={index}
                src={image.url}
                alt={image.description}
                title={image.description}
                onLoad={handleCarPreviewImageLoad}
                onError={handleCarPreviewImageError}
              />
            );
          })}
          infinite
          activeIndex={focusedImageIndex > -1 ? focusedImageIndex : undefined}
          animationType="fadeout"
          renderDotsItem={e => {
            return (
              <ScDotItemWrapper $active={e.isActive}>
                {loadedCarPreviewImages[e.activeIndex] ? (
                  <Image
                    id={String(e.activeIndex)}
                    key={e.activeIndex}
                    src={carPreviewImages[e.activeIndex].url}
                    alt={carPreviewImages[e.activeIndex].description}
                    title={carPreviewImages[e.activeIndex].description}
                    onLoad={handleCarPreviewImageLoad}
                  />
                ) : (
                  <ScNoImageIconDot key={e.activeIndex} />
                )}
              </ScDotItemWrapper>
            );
          }}
          disableButtonsControls
          responsive={{
            0: {
              items: 1,
            },
          }}
        />
      </ScPreviewCarPreviewWrapper>
    </Modal>
  );
};

export default React.memo(CarPreview, (prevProps, nextProps) => {
  return prevProps.carPreviewImages.length === nextProps.carPreviewImages.length;
});
