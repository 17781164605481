import styled from 'styled-components';

const Caption = styled.span<{ $truncated?: boolean }>`
  ${({ $truncated = true }) =>
    $truncated
      ? `
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  `
      : ''}

  font-size: 1.15em;
  font-weight: 500;
  color: #464646;
  text-align: left;
  text-transform: capitalize;
  line-height: inherit;
`;

export default Caption;
