import styled from 'styled-components';

export const ScImageSlotWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  @media (aspect-ratio: 64 / 27) {
    height: auto;
  }
`;

export const ScImageSlotErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScNoDataWrapper = styled.div`
  height: 13.625em;
`;
