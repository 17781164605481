import styled from 'styled-components';

export const ScSummaryCarouselWrapper = styled.div`
  position: relative;
  padding-bottom: 1.5em;

  .alice-carousel__dots {
    margin: 0;

    &-item div {
      margin-right: 0.375em;
      width: 0.625em;
      height: 0.625em;
    }
  }

  height: 100%;

  .alice-carousel {
    height: 100%;
  }

  .alice-carousel > div {
    height: 100%;
  }

  .alice-carousel__wrapper {
    height: 100%;
  }

  @media (aspect-ratio: 64 / 27) {
    .alice-carousel__dots {
      display: none;
    }
  }
`;
