import React, { FC } from 'react';
import VendorModal from 'react-bootstrap/Modal';

type Props = {
  className?: string;
  show: boolean;
  fullscreen?: string | true | undefined;
  onHide?(): void;
  trigger?: React.ReactNode;
  size?: React.ComponentProps<typeof VendorModal>['size'];
};

const Modal: FC<React.PropsWithChildren<Props>> = ({
  className,
  show,
  fullscreen,
  onHide,
  children,
  trigger,
  size,
}) => {
  return (
    <>
      {trigger}
      <VendorModal
        show={show}
        fullscreen={fullscreen}
        onHide={onHide}
        size={size}
        className={className}
      >
        {children}
      </VendorModal>
    </>
  );
};

export const ModalHeader = VendorModal.Header;
export const ModalBody = VendorModal.Body;

export default Modal;
