import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Listing from '../../Listing';
import SlotTextGroup from '../../SlotTextGroup';
import { ScListingWrapper } from './Model.styles';

type Props = {
  modelYear: string;
  selectedModel: [string, string][];
};

const Model: FC<Props> = ({ modelYear, selectedModel }) => {
  const { t } = useTranslation();

  return (
    <>
      <SlotTextGroup title={`${t('MODEL_TITLE')}: ${modelYear ?? '...'}`} />
      <ScListingWrapper>
        <Listing data={selectedModel} />
      </ScListingWrapper>
    </>
  );
};

export default Model;
