import { useEffect, useRef, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { StorageKeys } from 'types/common';

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const query = useRef<Record<string, string>>({});

  useEffect(() => {
    const searchString = window.location.search.replace('?', '');
    searchString.split('&').forEach(searchItem => {
      const [key, value] = searchItem.split('=');
      query.current[key] = value;
    });

    const baseUrl = process.env.REACT_APP_STREAMING_DOMAIN as string;
    const { customer_id: customerId, host } = query.current;

    fetch(`https://${baseUrl}/api/terminals?customerId=${customerId}&host=${host}`)
      .then(res => res.json())
      .then(res => {
        const [terminal] = res.data;

        const connectionId = localStorage.getItem(StorageKeys.connectionId);

        if (!terminal.connectionId) {
          localStorage.removeItem(StorageKeys.connectionId);
          setIsAuthenticated(true);
          return;
        }

        if (!connectionId) {
          localStorage.setItem(StorageKeys.connectionId, terminal.connectionId);
          setIsAuthenticated(true);
          return;
        }

        const result = Boolean(terminal.connectionId === connectionId);
        if (result) {
          setIsAuthenticated(true);
          return;
        }

        localStorage.removeItem(StorageKeys.connectionId);
        setIsAuthenticated(false);
      })
      .catch(() => {
        localStorage.removeItem(StorageKeys.connectionId);
        setIsAuthenticated(false);
      })
      .finally(() => setIsDone(true));
  }, []);

  if (!isDone) return <div>Loading...</div>;

  return isAuthenticated ? <Outlet /> : <Navigate to={`/?host=${query.current.host}`} />;
};

export default PrivateRoute;
