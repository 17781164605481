import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { streamingSelectors } from '../store';
import { useStreaming } from '../context/StreamingProvider';
import {
  ScDetailedPreviewWrapper,
  ScMainPreviewWrapper,
  ScPreviewStack,
  ScPreviewWrapper,
} from './Preview.styles';
import MainPreview from '../components/Preview/MainPreview';
import DetailedPreview from '../components/Preview/DetailedPreview';
import { Helmet } from 'react-helmet';
import CarPreview from 'components/Preview/CarPreview';
import SelectedRecordsPreview from 'components/Preview/SelectedRecordsPreview';
import Splash from 'components/Splash';
import PackageDetails from 'components/Preview/PackageDetails';

export const IMAGE_GALLERY_THUMBNAIL_WIDTH = 100;
export const IMAGE_GALLERY_THUMBNAIL_HEIGHT = 56;
export const NO_IMAGE_PATH = '/images/no-image.svg';

const Preview: FC = () => {
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const [withSplash, setWithSplash] = useState(false);

  const { setup } = useStreaming();
  const slots = useSelector(streamingSelectors.getSlots);
  const screen = useSelector(streamingSelectors.getScreen);
  const terminal = useSelector(streamingSelectors.getTerminal);
  const carPreviewImages = useSelector(streamingSelectors.getCarPreviewImages);
  const selectedRecordsImages = useSelector(streamingSelectors.getSelectedRecordsImages);
  const languageCode = useSelector(streamingSelectors.getLanguageCode);
  const withSummaryPrice = terminal?.flags.withSummaryPrice ?? true;

  const { t } = useTranslation();

  const onHideSplash = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setWithSplash(false);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const debounced = useDebouncedCallback(cb => {
    cb();
  }, 1000);

  const canShowDetailedPreview = useMemo<boolean>(() => {
    if (screen === 'summary' && !withSummaryPrice) return false;

    return true;
  }, [screen, withSummaryPrice]);

  useEffect(() => {
    setIsInitialLoaded(true);
    setWithSplash(true);
  }, []);

  useEffect(() => {
    debounced(() => {
      setup();
    });
  }, [debounced, setup]);

  useEffect(() => {
    if (!isInitialLoaded) {
      onHideSplash();
      return;
    }

    if (screen !== 'selection') return;

    const hasSelectionImages = !![
      ...slots.selection.categoryImages,
      ...slots.selection.mainImages,
      ...slots.selection.optionsImages,
      ...slots.selection.previewImages,
    ].length;

    if (hasSelectionImages) {
      onHideSplash();
    } else {
      setWithSplash(true);
    }
  }, [slots, screen, onHideSplash, isInitialLoaded]);

  return (
    <>
      {withSplash && <Splash locale={languageCode} />}
      {!!carPreviewImages.length && <CarPreview carPreviewImages={carPreviewImages} />}
      {!!selectedRecordsImages.length && (
        <SelectedRecordsPreview
          previewImages={selectedRecordsImages}
          scrollYPos={slots.global.scrollYPos}
        />
      )}
      <PackageDetails />

      <Helmet>
        <title>
          {t('PAGE_TITLE')}: {terminal?.customerId ?? ''}
        </title>
      </Helmet>
      <ScPreviewWrapper>
        <ScPreviewStack direction="horizontal" $maxSpace={!canShowDetailedPreview}>
          <ScMainPreviewWrapper $maxSpace={!canShowDetailedPreview}>
            <MainPreview slots={slots} screen={screen} />
          </ScMainPreviewWrapper>
          {canShowDetailedPreview && (
            <ScDetailedPreviewWrapper>
              <DetailedPreview
                slots={slots}
                screen={screen}
                terminal={terminal}
                previewImages={slots.selection.previewImages}
                optionType={slots.selection.optionType}
              />
            </ScDetailedPreviewWrapper>
          )}
        </ScPreviewStack>
      </ScPreviewWrapper>
    </>
  );
};

export default Preview;
