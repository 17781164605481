import styled from 'styled-components';

export const ScDetailedPreviewWrapper = styled.div`
  position: relative;
  height: 100%;
  max-height: calc(100vh - 4.375em);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (aspect-ratio: 64 / 27) {
    max-height: calc(100vh - 3.75em);
  }
`;
