import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SlotTextGroup from '../../SlotTextGroup';
import { ScPriceListDescription } from './PriceList.styles';

type Props = {
  name: string;
  description: string;
  modelYear: string;
  companyName: string;
};

const PriceList: FC<Props> = ({ name, description, modelYear, companyName }) => {
  const { t } = useTranslation();

  return (
    <SlotTextGroup title={t('PRICE_LIST_TITLE')}>
      {name && (
        <>
          {companyName} {modelYear}
        </>
      )}
      {description && <ScPriceListDescription>{description}</ScPriceListDescription>}
    </SlotTextGroup>
  );
};

export default PriceList;
