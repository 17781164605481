import Loader from '../../Loader';
import { FC, useState, useCallback } from 'react';
import AliceCarousel from 'react-alice-carousel';

import Image from '../../Image';
import { ScDotItem } from '../styles';
import { ScSummaryCarouselWrapper } from './Summary.styles';

type Props = {
  previewImages: string[];
};

const Summary: FC<Props> = ({ previewImages }) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const handleImageLoad = useCallback(() => {
    setHasLoaded(true);
  }, []);

  return (
    <ScSummaryCarouselWrapper>
      {!hasLoaded && <Loader />}
      <AliceCarousel
        animationType="fadeout"
        keyboardNavigation
        autoPlay
        disableButtonsControls
        infinite
        autoPlayInterval={7000}
        mouseTracking
        touchTracking
        disableDotsControls={!hasLoaded}
        renderDotsItem={e => {
          return (
            <ScDotItem
              $isActive={e.isActive}
              $isLast={e.activeIndex === previewImages.length - 1}
            />
          );
        }}
        items={previewImages.map((url, index) => {
          return (
            <Image
              key={index}
              src={url}
              loading="lazy"
              alt={url}
              height="calc(100vh - 10.5em)"
              onLoad={handleImageLoad}
            />
          );
        })}
      />
    </ScSummaryCarouselWrapper>
  );
};

export default Summary;
