import styled, { css } from 'styled-components';
import { ReactComponent as NoImageIcon } from 'images/no-image.svg';

export const ScPreviewCarPreviewWrapper = styled.div<{
  $width: string;
}>`
  height: inherit;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 1.5em 0.5em 0 0.5em;
  padding-bottom: 0;

  .alice-carousel {
    margin: 0;
  }

  .alice-carousel div:not(li.alice-carousel__dots-item div) {
    height: 100%;
  }

  .alice-carousel > div {
    height: auto;
    padding: 0 1em;
  }

  .alice-carousel__dots {
    display: inline-flex;
    justify-content: space-between;
    margin: 0;
    margin-top: 1.5em;
  }

  .alice-carousel__wrapper {
    height: auto;
  }

  .alice-carousel__stage {
    height: auto;
  }

  .alice-carousel__stage-item img {
    height: 70vh;
  }
`;

export const ScDotItemWrapper = styled.span<{ $active: boolean }>`
  margin: 0 1em;
  display: inline-flex;
  height: 100%;
  width: calc(100% - 2em);
  border: ${({ $active }) =>
    $active ? '0.15em solid #2A609D' : '0.15em solid transparent'};
  cursor: pointer;
`;

const commonNoImageStyles = css`
  display: block;
  margin: auto;
`;

export const ScNoImageIconMain = styled(NoImageIcon)`
  ${commonNoImageStyles}
  height: 50vh;
`;

export const ScNoImageIconDot = styled(NoImageIcon)`
  ${commonNoImageStyles}
`;
