import styled from 'styled-components';

export const ScWrapper = styled.div`
  height: inherit;
  overflow-y: scroll;
  padding: 1.5em 0.5em 0 0.5em;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const ScContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1em;
  row-gap: 1em;
`;

export const ScText = styled.div`
  font-weight: 400;
  font-size: 1em;
  color: #464646;
  margin-bottom: 0.5em;
`;

export const ScOptionsList = styled.ul`
  font-weight: 400;
  font-size: 1em;
  color: #464646;
`;
