import styled from 'styled-components';
import { Stack, Offcanvas } from 'react-bootstrap';
import ImageSlot from 'components/ImageSlot';

export const ScMainPreviewCarouselWrapper = styled.div`
  position: relative;
  height: inherit;

  .alice-carousel {
    position: absolute;
    top: 0;
    left: 0;
  }

  .alice-carousel div:not(li.alice-carousel__dots-item div),
  .alice-carousel {
    height: 100%;
  }

  .alice-carousel__dots {
    margin: 0;

    &-item div {
      margin-right: 0.375em;
      width: 0.625em;
      height: 0.625em;
    }
  }

  @media (aspect-ratio: 64 / 27) {
    .alice-carousel__dots {
      display: none;
    }
  }
`;

export const ScSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// need to overlap over carousel the loader
export const ScNoDataOverlay = styled.div`
  position: relative;
  height: 100%;
  z-index: 1000;
`;

export const ScOptionsSlotsWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-top: 2em;

  @media (aspect-ratio: 64 / 27) {
    height: 26%;
    margin-top: 1em;
  }
`;

export const ScOffcanvas = styled(Offcanvas)`
  && {
    width: 36%;
    top: 4.375em;
  }
`;

export const ScOffcanvasBody = styled(Offcanvas.Body)`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const ScImageSlot = styled(ImageSlot)`
  height: 90%;
`;
