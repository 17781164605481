import { FC, useState, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionType, PreviewImage } from '../../../types/streaming';
import {
  NoContent,
  ScClose,
  ScDynamicSlotWrapper,
  ScModal,
  ScStaticPackagePreviewWrapper,
  ScOptionsList,
  ScOptionItem,
} from './Selection.styles';
import VerticalCarousel from '../VerticalCarousel';
import { ReactComponent as VolvoLogo } from '../../../images/volvo-logo.svg';
import ImageCard from '../../ImageCard';
import NoData from '../../NoData';
import ImageSlot from '../../ImageSlot';
import { ScCaption, ScText } from '../styles';

type Props = {
  previewImages: PreviewImage[];
  type: 'static' | 'dynamic';
  optionType: OptionType | null;
};

const Selection: FC<Props> = ({ previewImages, type, optionType }) => {
  const [selectedImage, setSelectedImage] = useState<PreviewImage | null>(null);

  const { t } = useTranslation();

  const getModalContent = (image: PreviewImage): React.ReactNode => {
    if (!image) return <></>;

    return (
      <ImageCard
        errorIcon={VolvoLogo}
        url={image.url}
        name={image.name}
        defaultDescription={t('PACKAGE_DEFAULT_DESCRIPTION')}
      >
        <ScCaption>{image.name}</ScCaption>
        {image.description && <ScText>{image.description}</ScText>}
        {image.options.length > 0 && (
          <>
            <ScText>{t('PACKAGE_CONTAIN_LABEL')}:</ScText>
            <ScOptionsList>
              {image.options.map(option => (
                <ScOptionItem key={option}>{option}</ScOptionItem>
              ))}
            </ScOptionsList>
          </>
        )}
      </ImageCard>
    );
  };

  const getNoContentMessage = useCallback(
    (optionType: OptionType | null) => {
      if (optionType === OptionType.PACKAGE) {
        return t('PACKAGE_NOT_SELECTED');
      }

      if (optionType === OptionType.OPTION) {
        return t('OPTION_NOT_SELECTED');
      }

      return t('ACCESSORY_NOT_SELECTED');
    },
    [t],
  );

  if (!previewImages.length) {
    return (
      <NoData>
        <NoContent>{getNoContentMessage(optionType)}</NoContent>
      </NoData>
    );
  }

  return (
    <>
      {type === 'dynamic' && (
        <ScModal
          show={!!selectedImage}
          onHide={() => setSelectedImage(null)}
          size="lg"
          centered
        >
          <ScClose onClick={() => setSelectedImage(null)} />
          {getModalContent(selectedImage as PreviewImage)}
        </ScModal>
      )}

      {type === 'static' ? (
        <ScStaticPackagePreviewWrapper>
          <VerticalCarousel
            itemsToShow={3}
            itemPadding={[20, 1]}
            scrollContainerId="detailed-preview"
          >
            {previewImages.map(image => {
              const displayName = image.name;

              return (
                <ImageCard
                  errorIcon={VolvoLogo}
                  url={image.url}
                  name={displayName}
                  key={displayName}
                  defaultDescription={t('PACKAGE_DEFAULT_DESCRIPTION')}
                >
                  <ScCaption $truncated={optionType === OptionType.PACKAGE}>
                    {displayName}
                  </ScCaption>
                  {image.description && <ScText>{image.description}</ScText>}
                  {image.options.length > 0 && (
                    <>
                      <ScText>{t('PACKAGE_CONTAIN_LABEL')}:</ScText>
                      <ScOptionsList>
                        {image.options.map(option => (
                          <ScOptionItem key={option}>{option}</ScOptionItem>
                        ))}
                      </ScOptionsList>
                    </>
                  )}
                </ImageCard>
              );
            })}
          </VerticalCarousel>
        </ScStaticPackagePreviewWrapper>
      ) : (
        <VerticalCarousel itemsToShow={3} scrollContainerId="detailed-preview">
          {previewImages.map(image => {
            return (
              <Fragment key={image.name}>
                {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <ScDynamicSlotWrapper
                  key={image.url}
                  onClick={() => setSelectedImage(image)}
                >
                  <ImageSlot
                    image={image}
                    errorIcon={VolvoLogo}
                    defaultDescription={image.name}
                  />
                  <ScCaption
                    title={image.name}
                    $truncated={optionType === OptionType.PACKAGE}
                  >
                    {image.name}
                  </ScCaption>
                </ScDynamicSlotWrapper>
              </Fragment>
            );
          })}
        </VerticalCarousel>
      )}
    </>
  );
};

export default Selection;
