import { configureStore, Reducer } from '@reduxjs/toolkit';

import { streamingReducer } from './streaming';
import { ApplicationState } from './types';

export const reducers: Record<keyof ApplicationState, Reducer> = {
  streaming: streamingReducer,
};

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: false }),
  devTools: process.env.REACT_APP_IS_LOCAL === 'true',
});

export const { dispatch } = store;
