import Loader from '../../Loader';
import { FC, useCallback, useEffect, useState } from 'react';
import { ScPreviewImage } from '../styles';
import { ScPriceListPreviewImageWrapper } from './PriceList.styles';

type Props = {
  url: string;
};

const PriceList: FC<Props> = ({ url }) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const handleImageLoad = useCallback(() => {
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    if (!url) {
      setHasLoaded(true);
    }
  }, [url]);

  return (
    <ScPriceListPreviewImageWrapper>
      {!hasLoaded && <Loader />}
      <ScPreviewImage src={url} onLoad={handleImageLoad} />
    </ScPriceListPreviewImageWrapper>
  );
};

export default PriceList;
