import { StorageKeys } from 'types/common';
import { UrlTransform } from './urlTransform';
import * as Sentry from '@sentry/react';

type InitArgs = {
  onmessage?: (message) => void;
};

type Result = {
  init: (initArgs?: InitArgs) => Promise<WebSocket>;
  send: (message) => void;
  get: () => WebSocket | undefined;
  close: () => void;
  isReady: () => boolean;
};

const isReady = (connection: WebSocket | undefined) => connection?.readyState === 1;

const createConnection = (): Result => {
  let connection: WebSocket | undefined;

  return {
    init({ onmessage } = {}) {
      return new Promise((resolve, reject) => {
        if (isReady(connection)) return;

        if (connection && !isReady(connection)) {
          this.close();
        }

        const query = UrlTransform.parseQuery(window.location.search);
        const { host, customer_id: customerId } = query;
        const baseApiDomain = process.env.REACT_APP_STREAMING_DOMAIN;

        if (!host || !customerId || !baseApiDomain) {
          throw new Error('One or more required parameters missing.');
        }

        const connectionId = localStorage.getItem(StorageKeys.connectionId);

        connection = new WebSocket(
          `wss://${baseApiDomain}/streaming?customer_id=${customerId}&host=${host}${
            connectionId ? `&connection_id=${connectionId}` : ''
          }`,
        );

        if (process.env.REACT_APP_IS_LOCAL === 'true') {
          window._streaming_ws_connection = connection;
        }

        if (connection && onmessage) {
          connection.onmessage = onmessage.bind(connection);
        }

        connection.onopen = () => {
          this.send({
            type: 'initialize',
            data: {
              customerId,
              host,
            },
          });
          resolve(connection as WebSocket);
        };

        connection.onerror = event => {
          Sentry.withScope(scope => {
            scope.setTag('ws', 'error');
            Sentry.captureException(event);
          });
          reject(event);
        };
      });
    },
    send(message) {
      connection?.send(JSON.stringify(message));
    },
    get() {
      return connection;
    },
    close() {
      if (connection?.readyState === 0 || connection?.readyState === 1) {
        connection.close();
        connection = undefined;
      }
    },
    isReady() {
      return isReady(connection);
    },
  };
};

export default createConnection;
