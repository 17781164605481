import React, { useEffect, useRef } from 'react';
import { ScVerticalCarouselPreviewWrapper } from './VerticalCarousel.styles';
import Carousel, { ReactElasticCarouselProps } from 'react-elastic-carousel';
import { debounce } from 'utils';

// @todo: think about merging to lib
// unfortunately by default it does not expose the types
type CarouselRef = {
  slideNext(): void;
  slidePrev(): void;
};

type Props = React.PropsWithChildren<
  {
    itemsToShow: number;
    scrollContainerId: string;
  } & Omit<ReactElasticCarouselProps, 'isRTL'>
>;

const VerticalCarousel: React.FC<Props> = ({
  itemsToShow,
  scrollContainerId,
  children,
  ...carouselProps
}) => {
  const carouselRef = useRef<Carousel | null>(null);

  useEffect(() => {
    function wrapperScrollHandler(event: WheelEvent): void {
      // either 1 or -1 depending on whether wheel up / down
      const delta = Math.sign(event.deltaY);

      const carousel = carouselRef.current as unknown as CarouselRef;

      if (delta === 1) {
        carousel.slideNext();
        return;
      }

      carousel.slidePrev();
    }

    // avoid jumps during wheel event
    const debounced = debounce(wrapperScrollHandler, 300);

    const wrapper = document.getElementById(scrollContainerId);

    wrapper?.addEventListener('wheel', debounced);

    return () => {
      wrapper?.removeEventListener('wheel', debounced);
    };
  }, [scrollContainerId]);

  return (
    <ScVerticalCarouselPreviewWrapper>
      {/* for some reason type does not allow to pass children prop inside */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Carousel
        itemsToShow={itemsToShow}
        verticalMode
        showArrows={false}
        renderPagination={() => <></>}
        ref={carouselRef}
        transitionMs={700}
        {...carouselProps}
      >
        {children}
      </Carousel>
    </ScVerticalCarouselPreviewWrapper>
  );
};

export default VerticalCarousel;
