import { Stack } from 'react-bootstrap';
import styled from 'styled-components';
import Button from './Button';

export const ScHeader = styled.div<{ $isDark: boolean }>`
  padding: 1.25em 1.5em;
  height: 4.375em;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  border: ${({ $isDark }) => ($isDark ? 'none' : '1px solid #f6f7f7c7')};
  background: ${({ $isDark }) => ($isDark ? 'black' : 'white')};
  box-shadow: 0px 4px 8px 0px #0000000f, 0px 0px 4px 0px #0000000a;

  @media (aspect-ratio: 64 / 27) {
    padding: 0.75em 1.5em;
    height: 3.75em;
  }
`;

export const ScConnectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScConnectionIcon = styled.span<{ $color: string }>`
  position: absolute;
  top: 0;
  right: -1.1em;
  display: inline-block;
  width: 0.813em;
  height: 0.813em;
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
`;

export const ScConnectionTitle = styled.span<{ $isDark: boolean }>`
  position: relative;
  font-weight: 500;
  color: ${({ $isDark }) => ($isDark ? 'white' : '#464646')};
  margin: 0;
`;

export const ScLogout = styled(Button)<{ $isDark: boolean }>`
  color: ${({ $isDark }) => ($isDark ? 'white' : '#464646')};
  width: 100%;
  font-size: 0.875em;
  margin: 0;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
    color: ${({ $isDark }) => ($isDark ? 'white' : '#464646')};
  }
`;

export const ScTitle = styled.p`
  text-align: center;
  font-size: 1.25em;
  margin: 0;
  flex: 1;
`;

export const ScIndicators = styled(Stack)`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
`;
