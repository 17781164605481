import { difference, intersection } from 'ramda';
import { PreviewImage, Image as ImageType } from '../types/streaming';

/**
 * Merges preview images based on previous state and new data received.
 * @param prevImages {PreviewImage[]} - current preview images.
 * @param newImages {PreviewImage[]} - new preview images based on current preview images.
 * @returns {PreviewImage[]} - merged preview images.
 */
export const getPreviewImages = (
  prevImages: PreviewImage[],
  newImages: PreviewImage[],
): PreviewImage[] => {
  if (prevImages.length === 0 || !intersection(prevImages, newImages).length) {
    return newImages;
  }

  if (newImages.length > prevImages.length) {
    return [...difference(newImages, prevImages), ...prevImages];
  }

  const toRemove = difference<PreviewImage>(prevImages, newImages).reduce<
    Record<string, true>
  >((res, previewImage) => {
    res[previewImage.name] = true;
    return res;
  }, {});

  return prevImages.filter(previewImage => {
    return !toRemove[previewImage.name];
  });
};

export const canAutoPlayCarPreview = (images: ImageType[]): boolean => {
  const newIndex = images.findIndex(image => image.focused);
  return newIndex === -1;
};

export const getCarPreviewImages = (
  prevImages: ImageType[],
  newImages: ImageType[],
  isLoaded: boolean,
): ImageType[] => {
  if (!isLoaded) return prevImages;

  const prevIndex = prevImages.findIndex(image => image.focused);

  if (canAutoPlayCarPreview(newImages)) {
    return newImages.map((image, index) => {
      if (index === prevIndex) {
        return { ...image, focused: true };
      }

      return image;
    });
  }

  return newImages;
};

export const getActionName = (NAME: string, SUB: string): string => `${NAME}/${SUB}`;
