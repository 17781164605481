import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

export const ScLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScLoader = styled(Spinner)`
    color: #464646;
    font-size: 24px
    z-dinex: 1000;
`;
