import Loader from '../../Loader';
import { FC, useCallback, useEffect, useState } from 'react';
import { ScPreviewImage } from '../styles';
import { ScModelPreviewImageWrapper } from './Model.styles';

type Props = {
  url: string | undefined;
};

const Model: FC<Props> = ({ url }) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const handleImageLoad = useCallback(() => {
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    if (!url) {
      setHasLoaded(true);
    }
  }, [url]);

  return (
    <ScModelPreviewImageWrapper>
      {!hasLoaded && <Loader />}
      <ScPreviewImage src={url} onLoad={handleImageLoad} />
    </ScModelPreviewImageWrapper>
  );
};

export default Model;
