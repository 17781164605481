import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import Layout from './components/Layout';
import { StreamingProvider } from './context/StreamingProvider';
import PrivateRoute from './routes/PrivateRoute';
import Login from './pages/Login';
import Preview from './pages/Preview';
import { store } from './store/init';

const App = () => {
  return (
    <Router>
      {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
      <Routes>
        <Route
          path="/"
          element={
            <Provider store={store}>
              <StreamingProvider>
                <Layout>
                  <Outlet />
                </Layout>
              </StreamingProvider>
            </Provider>
          }
        >
          <Route index element={<Login />} />

          <Route path="/page" element={<PrivateRoute />}>
            <Route index element={<Preview />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
