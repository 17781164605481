import ImageCard from 'components/ImageCard';
import { FC, useEffect, useMemo, useRef } from 'react';
import { PreviewImage } from 'types/streaming';
import { ScCaption } from './styles';
import { ReactComponent as VolvoLogo } from 'images/volvo-logo.svg';
import {
  ScOptionsList,
  ScText,
  ScWrapper,
  ScContentWrapper,
} from './SelectedRecordsPreview.styles';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import Badge from 'components/Badge';

type Props = {
  previewImages: PreviewImage[];
  scrollYPos: number | null;
};

const SelectedRecordsPreview: FC<Props> = ({ previewImages, scrollYPos }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const typeKeys = useMemo<Record<PreviewImage['type'], string>>(
    () => ({
      package: 'STREAMING_LABEL_PACKAGE',
      option: 'STREAMING_LABEL_OPTION',
      accessory: 'STREAMING_LABEL_ACCESSORY',
    }),
    [],
  );

  useEffect(() => {
    const targetElement = ref.current?.parentElement;

    if (!targetElement || typeof scrollYPos !== 'number') return;

    const targetYPos = targetElement.scrollHeight * (scrollYPos / 100);

    targetElement.scrollTo({
      top: targetYPos,
      behavior: 'smooth',
    });
  }, [scrollYPos]);

  return (
    <Modal show fullscreen>
      <ScWrapper>
        <ScContentWrapper ref={ref}>
          {previewImages.map(image => {
            return (
              <ImageCard
                key={image.name}
                errorIcon={VolvoLogo}
                url={image.url}
                name={image.name}
                defaultDescription={t('PACKAGE_DEFAULT_DESCRIPTION')}
              >
                <Badge>
                  {image.isDefault ? t('DEFAULT_LABEL') : t(typeKeys[image.type])}
                </Badge>
                <ScCaption $truncated={!!image.options.length}>{image.name}</ScCaption>
                {image.description && <ScText>{image.description}</ScText>}
                {!!image.options.length && (
                  <>
                    <ScText>{t('PACKAGE_CONTAIN_LABEL')}:</ScText>
                    <ScOptionsList>
                      {image.options.map(option => (
                        <li key={option}>{option}</li>
                      ))}
                    </ScOptionsList>
                  </>
                )}
              </ImageCard>
            );
          })}
        </ScContentWrapper>
      </ScWrapper>
    </Modal>
  );
};

export default SelectedRecordsPreview;
