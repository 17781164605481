export enum ConnectionStatus {
  Offline = 'offline',
  Busy = 'busy',
  Vacant = 'vacant',
}

export enum ImageType {
  Package = 'package',
  Option = 'option',
  Accessory = 'accessory',
}

export type Image = {
  url: string;
  description: string;
  focused: boolean;
  type: ImageType;
  isDefault: boolean;
  position?: number;
};

export type PreviewImage = Image & {
  options: string[];
  name: string;
};

export type Terminal = {
  id: number;
  customerId: string;
  // @todo: type if needed
  // eslint-disable-next-line @typescript-eslint/ban-types
  configurator: {};
  configuratorId: number | null;
  host: string;
  screen: string | null;
  status: ConnectionStatus;
  flags: Partial<{ type: 'static' | 'dynamic'; withSummaryPrice: boolean }>;
  createdAt: Date;
  updatedAt: Date;
  // @todo: think whether we need it for other entities
  lastLoginAt: Date | null;
  connectionId: string | null;
  lastConnectionClosed: Date | null;
};

export enum OptionType {
  OPTION = 'OPTION',
  ACCESSORY = 'ACCESSORY',
  PACKAGE = 'PACKAGE',
}

type OptionDetail = {
  label: string;
  description: string;
  url: string | null;
};

type PackageDetails = {
  previewImage: PreviewImage | null;
  includedOptions: OptionDetail[];
  exclusiveOptions: OptionDetail[];
  toRemove: OptionDetail[];
};

export type SelectionSlot = {
  mainImages: Image[];
  optionsImages: (Image & { position: number })[];
  previewImages: PreviewImage[];
  categoryImages: Image[];
  optionType: OptionType | null;
  packageDetails: PackageDetails | null;
};

export type SummarySlot = {
  previewImages: string[];
  fields: [string, string][];
  documentSrc: string;
  configurationModelName: string;
  imageSrc: string;
};

export type GlobalSlot = {
  modelName: string | null;
  isPublished: boolean;
  languageCode: string | null;
  configurationNumber: number | null;
  carPreviewImages: Image[];
  selectedRecordsImages: PreviewImage[];
  canAutoPlayCarPreview: boolean;
  scrollYPos: number | null;
};

export type PriceListSlot = {
  priceListName: string;
  priceListImage: string;
  priceListDescription: string;
  modelYear: string;
  companyName: string;
};

export type ModelSlot = {
  modelYear: string;
  previewImage: string | null;
  selectedModel: [string, string][];
};

export type Slots = {
  selection: SelectionSlot;
  summary: SummarySlot;
  global: GlobalSlot;
  priceList: PriceListSlot;
  model: ModelSlot;
};
