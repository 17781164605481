import { FC, memo, useEffect, useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Image from 'components/Image';
import { ScDotItem } from '../../styles';
import { Image as ImageData } from 'types/streaming';
import { equals } from 'ramda';

type Props = {
  mainPreviewImages: ImageData[];
  hasLoadedMainPreview: boolean;
  handleMainPreviewImageLoad(event: React.SyntheticEvent<HTMLImageElement, Event>): void;
};

const Carousel: FC<Props> = ({
  mainPreviewImages,
  hasLoadedMainPreview,
  handleMainPreviewImageLoad,
}) => {
  const ref = useRef<AliceCarousel | null>(null);
  const focusedImageIndex = mainPreviewImages.findIndex(
    previewImage => previewImage.focused,
  );

  useEffect(() => {
    if (focusedImageIndex === -1 || !ref.current || !hasLoadedMainPreview) return;

    ref.current.slideTo(focusedImageIndex);
  }, [focusedImageIndex, hasLoadedMainPreview]);

  return (
    <AliceCarousel
      ref={ref}
      animationType="fadeout"
      activeIndex={focusedImageIndex > -1 ? focusedImageIndex : undefined}
      keyboardNavigation
      autoPlay
      infinite
      mouseTracking
      touchTracking
      autoPlayInterval={7000}
      items={mainPreviewImages.map((image, index) => {
        return (
          <Image
            id={String(index)}
            key={index}
            src={image.url}
            alt={image.description}
            title={image.description}
            height="100%"
            onLoad={handleMainPreviewImageLoad}
          />
        );
      })}
      renderDotsItem={e => {
        return (
          <ScDotItem
            $isActive={e.isActive}
            $isLast={e.activeIndex === mainPreviewImages.length - 1}
          />
        );
      }}
      disableDotsControls={!hasLoadedMainPreview}
      disableButtonsControls
    />
  );
};

export default memo(Carousel, (prevProps, nextProps) => {
  if (
    prevProps.hasLoadedMainPreview !== nextProps.hasLoadedMainPreview ||
    !equals(prevProps.mainPreviewImages, nextProps.mainPreviewImages)
  )
    return false;
  return true;
});
