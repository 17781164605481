import {
  ParseOptions,
  parseUrl,
  ParsedUrl as ExternalParsedUrl,
  stringifyUrl,
  parse,
  ParsedQuery as ExternalParsedQuery,
  stringify,
  UrlObject as ExternalUrlObject,
} from 'query-string';

const OPTIONS: ParseOptions = {
  arrayFormat: 'bracket',
};

/** Adaptation for external types */
export type ParsedUrl = ExternalParsedUrl;
export type ParsedQuery<T = string> = ExternalParsedQuery<T>;
export type UrlObject = ExternalUrlObject;

/** Just an encapsulated adaptation for external library  */
export const UrlTransform = {
  parseUrl: (url: string): ParsedUrl => parseUrl(url, OPTIONS),
  stringifyUrl: (url: UrlObject): string => stringifyUrl(url, OPTIONS),
  /**
   * ## Parse query from string
   * @example Input: '1=2&3=4'; Output: {1: 2, 3: 4}
   * @example Input: '?1=2&3=4'; Output: {1: 2, 3: 4}
   */
  parseQuery: (url: string): ParsedQuery => parse(url, OPTIONS),
  stringifyQuery: <T = string | number>(url: ParsedQuery<T>): string =>
    stringify(url, OPTIONS),
};
