import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectionStatus } from '../types/streaming';
import {
  ScConnectionIcon,
  ScConnectionTitle,
  ScConnectionWrapper,
  ScHeader,
  // ScIndicators,
  ScLogout,
  ScTitle,
} from './Header.styles';
// import { ReactComponent as FlagIcon } from '../images/flag.svg';
// import { ReactComponent as PIcon } from '../images/p.svg';
// import { ReactComponent as ToolsIcon } from '../images/tools.svg';
// import { ReactComponent as ShieldIcon } from '../images/shield.svg';

type Props = {
  connectionStatus: ConnectionStatus;
  modelName: string | null;
  handleLogout(): void;
  className?: string;
  isDark?: boolean;
};

const Header: FC<Props> = ({
  connectionStatus,
  modelName,
  handleLogout,
  className,
  isDark = false,
}) => {
  const { t } = useTranslation();

  const statusColor = useMemo<string>(() => {
    if (connectionStatus === ConnectionStatus.Busy) return '#60D703';
    return '#F24848';
  }, [connectionStatus]);

  const connectionTitle = useMemo<string>(() => {
    if (connectionStatus === ConnectionStatus.Busy) return t('CONNECTED');

    return t('NOT_CONNECTED');
  }, [connectionStatus, t]);

  return (
    <ScHeader className={className} $isDark={isDark}>
      <ScConnectionWrapper>
        <ScConnectionTitle $isDark={isDark}>
          <ScConnectionIcon $color={statusColor} />
          {connectionTitle}
        </ScConnectionTitle>
        {connectionStatus !== ConnectionStatus.Offline && (
          <ScLogout variant="link" onClick={handleLogout} $isDark={isDark}>
            {t('LOGOUT')}
          </ScLogout>
        )}
      </ScConnectionWrapper>
      {!isDark && connectionStatus === ConnectionStatus.Busy && (
        <>
          {modelName && <ScTitle>{modelName}</ScTitle>}
          {/* @todo: wait for design and product comment on this */}
          {/* <ScIndicators direction="horizontal" gap={3}>
            <FlagIcon color="#989898" />
            <PIcon color="#989898" />
            <ToolsIcon color="#989898" />
            <ShieldIcon color="#989898" />
          </ScIndicators> */}
        </>
      )}
    </ScHeader>
  );
};

export default Header;
