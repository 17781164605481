import { FC, useMemo } from 'react';
import {
  ScListingCellKey,
  ScListingCellValue,
  ScListingColumn,
  ScListingRow,
  ScListingWrapper,
} from './Listing.styles';
import { nonNill } from 'utils/nonNill';

type Props = {
  data: ([string, string, boolean?] | undefined)[];
};

const Listing: FC<Props> = ({ data }) => {
  const cleanData = useMemo<NonNullable<Props['data'][number]>[]>(() => {
    return data.filter(nonNill).filter(cell => cell?.[0] && cell?.[1]);
  }, [data]);

  return (
    <ScListingWrapper>
      <ScListingColumn>
        {cleanData.map(cell => {
          const [key, value, isEmphasized = false] = cell;
          return (
            <ScListingRow key={`${key}${value}`} $isBold={isEmphasized}>
              <ScListingCellKey>{key}</ScListingCellKey>
              <ScListingCellValue>{value}</ScListingCellValue>
            </ScListingRow>
          );
        })}
      </ScListingColumn>
    </ScListingWrapper>
  );
};

export default Listing;
