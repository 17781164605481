import styled from 'styled-components';

export const ScImageCardWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
`;

export const ScContent = styled.div`
  padding: 1em 1.5em 1.25em 1.5em;
`;

export const ScImageWrapper = styled.div`
  height: 13.625em;
`;
