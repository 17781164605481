import React from 'react';
import {
  ScBodyWrapper,
  ScCardTextGroup,
  ScContentWrapper,
  ScExclusiveOptionsLink,
  ScHeaderImage,
  ScHeading,
  ScImageWrapper,
  ScModal,
  ScOptionCard,
  ScSection,
  ScSectionSubtitle,
  ScSectionTitle,
  ScTextFlow,
} from './PackageDetails.styles';
import { useSelector } from 'react-redux';
import { streamingSelectors } from 'store';
import ImageCard from 'components/ImageCard';
import { ReactComponent as VolvoLogo } from 'images/volvo-logo.svg';
import { useTranslation } from 'react-i18next';

const SECTION_TO_LABEL = {
  includedOptions: 'PACKAGE_DETAILS_INCLUDED_OPTIONS_TITLE',
  exclusiveOptions: 'PACKAGE_DETAILS_EXCLUSIVE_OPTIONS_TITLE',
  toRemove: 'PACKAGE_DETAILS_TO_REMOVE_OPTIONS_TITLE',
};

const PackageDetails: React.FC = () => {
  const { t } = useTranslation();

  const packageDetails = useSelector(streamingSelectors.getPackageDetails);

  if (!packageDetails) return null;

  return (
    <ScModal show fullscreen>
      <ScHeaderImage
        errorIcon={VolvoLogo}
        url={packageDetails?.previewImage?.url ?? ''}
        name={packageDetails?.previewImage?.name ?? ''}
        defaultDescription=""
      />
      <ScBodyWrapper>
        {['includedOptions', 'exclusiveOptions', 'toRemove']
          .filter(section => packageDetails?.[section]?.length > 0)
          .map(section => {
            return (
              <ScSection key={section}>
                <ScSectionTitle>{t(SECTION_TO_LABEL[section])}</ScSectionTitle>
                {section === 'exclusiveOptions' && (
                  <ScSectionSubtitle>
                    {t('PACKAGE_DETAILS_EXCLUSIVE_OPTIONS_SUBTITLE')}{' '}
                    <ScExclusiveOptionsLink>
                      {t('PACKAGE_DETAILS_EXCLUSIVE_OPTIONS_LINK')}
                    </ScExclusiveOptionsLink>
                  </ScSectionSubtitle>
                )}
                <ScContentWrapper>
                  {packageDetails?.[section].map(option => {
                    return (
                      <ScOptionCard key={option.label} $blured={section === 'toRemove'}>
                        <ScImageWrapper>
                          <ImageCard
                            key={option.label}
                            errorIcon={VolvoLogo}
                            url={option.url ?? ''}
                            name={option.label}
                            defaultDescription={t('PACKAGE_DEFAULT_DESCRIPTION')}
                          />
                        </ScImageWrapper>
                        {(option.label || option.description) && (
                          <ScCardTextGroup>
                            {option.label && <ScHeading>{option.label}</ScHeading>}
                            {option.description && (
                              <ScTextFlow>
                                {option.description.length >= 250
                                  ? `${option.description.slice(0, 251)}...`
                                  : option.description}
                              </ScTextFlow>
                            )}
                          </ScCardTextGroup>
                        )}
                      </ScOptionCard>
                    );
                  })}
                </ScContentWrapper>
              </ScSection>
            );
          })}
      </ScBodyWrapper>
    </ScModal>
  );
};

export default PackageDetails;
