import styled, { css } from 'styled-components';
import { Image } from 'react-bootstrap';
import { ReactComponent as VolvoLogo } from 'images/volvo-logo.svg';

export const ScImageWrapper = styled.div<{ $center?: boolean }>`
  pointer-events: none;
  text-align: center;
  height: 100%;

  ${({ $center }) =>
    $center
      ? `
    display: flex;
    align-items: center;
    justify-content: center;
  `
      : ''}
`;

const imageStyles = css`
  display: inline-block;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  width: 99%;
`;

export const ScImage = styled(Image)<{
  $height: number | string;
}>`
  height: ${({ $height }) => (typeof $height === 'number' ? `${$height}px` : $height)};

  ${imageStyles}
`;

export const ScNoImage = styled(VolvoLogo)<{
  $height: number | string;
}>`
  height: ${({ $height }) => (typeof $height === 'number' ? `${$height}px` : $height)};

  ${imageStyles}
`;
