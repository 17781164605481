import { FC, PropsWithChildren } from 'react';
import {
  ScSlotTextGroupContent,
  ScSlotTextGroupTitle,
  ScSlotTextGroupWrapper,
} from './SlotTextGroup.styles';

type Props = PropsWithChildren<{
  title: string;
  withBorder?: boolean;
}>;

const SlotTextGroup: FC<Props> = ({ title, children, withBorder = true }) => {
  return (
    <ScSlotTextGroupWrapper $withBorder={withBorder}>
      <ScSlotTextGroupTitle>{title}</ScSlotTextGroupTitle>
      <ScSlotTextGroupContent>{children}</ScSlotTextGroupContent>
    </ScSlotTextGroupWrapper>
  );
};

export default SlotTextGroup;
