import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const ScInput = styled(Form.Control)`
  background: transparent;
  padding: 1em;
  width: 100%;
  border: 1px solid rgba(152, 152, 152, 0.25);
  color: black;
  border-radius: 0;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(152, 152, 152, 0.25);
    background: transparent;
    color: black;
  }

  &::placeholder {
    color: #989898;
  }
`;
