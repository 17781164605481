import { ScPageWrapper, ScText, ScImageWrapper } from './Splash.styles';
import { ReactComponent as VolvoLogo } from '../images/volvo-logo.svg';
import { FC } from 'react';

// Splash may be displayed before translations are loaded
// Thus we need to make sure no text jumps happpen on first load
// For this reason we hard-code the translations for splash screen
const localeToTranslation: Record<string, string | undefined> = {
  'nl-BE': 'Configuratie voorbereiden',
  'fr-FR': 'Préparation de la configuration',
  'en-US': 'Preparing your car',
};

type Props = {
  locale: string | null;
};

const Splash: FC<Props> = ({ locale }) => {
  return (
    <ScPageWrapper>
      <ScImageWrapper>
        <VolvoLogo />
      </ScImageWrapper>
      <ScText $visible={!!locale}>{localeToTranslation[locale as string]}...</ScText>
    </ScPageWrapper>
  );
};

export default Splash;
