import React, { FC } from 'react';
import { NoDataBackground, NoDataContent, NoDataWrapper } from './NoData.styles';

type Props = React.PropsWithChildren;

const NoData: FC<Props> = ({ children }) => {
  return (
    <NoDataWrapper>
      <NoDataBackground />
      <NoDataContent>{children}</NoDataContent>
    </NoDataWrapper>
  );
};

export default NoData;
