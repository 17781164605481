import { FC, PropsWithChildren } from 'react';
import { ButtonProps as VendorButtonProps } from 'react-bootstrap/Button';
import { ScButtonLink, ScButtonPrimary } from './Button.styles';

type Props = PropsWithChildren<{
  className?: VendorButtonProps['className'];
  variant?: 'primary' | 'link';
  type?: VendorButtonProps['type'];
  onClick?: VendorButtonProps['onClick'];
  disabled?: boolean;
  href?: VendorButtonProps['href'];
  target?: VendorButtonProps['target'];
}>;

const variantToComponent = {
  primary: ScButtonPrimary,
  link: ScButtonLink,
};

const Button: FC<Props> = ({
  className,
  variant = 'primary',
  type,
  children,
  onClick,
  disabled = false,
  href,
  target,
}) => {
  const Component = variantToComponent[variant] ?? null;

  return (
    <Component
      className={className}
      variant={variant}
      type={type}
      onClick={onClick}
      disabled={disabled}
      href={href}
      target={target}
    >
      {children}
    </Component>
  );
};

export default Button;
