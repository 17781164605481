import styled from 'styled-components';

export const ScSlotTextGroupWrapper = styled.div<{ $withBorder: boolean }>`
  position: relative;

  ${({ $withBorder }) =>
    $withBorder
      ? `
      padding-bottom: 1.5em;
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(152, 152, 152, 0.25);
        height: 1px;
      }`
      : ''}
`;

export const ScSlotTextGroupTitle = styled.h3`
  font-weight: 500;
  font-size: 2.25em;
  color: #090909;
  position: relative;
  margin: 0;
`;

export const ScSlotTextGroupContent = styled.div`
  padding-top: 0.35em;
  color: #464646;
  font-weight: 500;
  font-size: 1.5em;

  @media (aspect-ratio: 64 / 27) {
    margin-bottom: 0.2em;
  }
`;
