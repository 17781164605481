import { ScLoader, ScLoaderWrapper } from './Loader.styles';

const Loader = () => {
  return (
    <ScLoaderWrapper>
      <ScLoader animation="border" role="status" />
    </ScLoaderWrapper>
  );
};

export default Loader;
