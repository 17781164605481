import React, { FC } from 'react';
import { ScContent, ScImageCardWrapper, ScImageWrapper } from './ImageCard.styles';
import ImageSlot from './ImageSlot';

type Props = React.PropsWithChildren<{
  className?: string;
  name: string;
  url: string;
  errorIcon: React.ComponentProps<typeof ImageSlot>['errorIcon'];
  defaultDescription: string;
  onClick?(): void;
}>;

const ImageCard: FC<Props> = ({
  className,
  children,
  name,
  url,
  errorIcon,
  defaultDescription,
  onClick,
}) => {
  return (
    <ScImageCardWrapper className={className} onClick={onClick}>
      <ScImageWrapper>
        <ImageSlot
          image={{ url, description: name }}
          errorIcon={errorIcon}
          defaultDescription={defaultDescription}
        />
      </ScImageWrapper>
      {children && <ScContent>{children}</ScContent>}
    </ScImageCardWrapper>
  );
};

export default ImageCard;
