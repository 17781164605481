import styled from 'styled-components';

export const ScPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ScFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20.938em;
  padding: 3.625em 1.5em 2.625em 1.5em;
  box-sizing: content-box;
  box-shadow: 0px 4px 8px 0px #0000000f, 0px 0px 4px 0px #0000000a;
`;

export const ScImageWrapper = styled.div`
  width: 12.5em;
  height: 12.5em;
  margin-bottom: 4.125em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScInputWrapper = styled.div`
  margin-bottom: 0.938em;
  width: 100%;
`;
