import styled, { keyframes } from 'styled-components';

const animateDots = keyframes`
    to {
        clip-path: inset(0 -1.2ch 0 0)
    }
`;

export const ScPageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ScImageWrapper = styled.div`
  width: 12.5em;
  height: 12.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScText = styled.span<{ $visible: boolean }>`
  font-weight: 500;
  font-size: 1.5em;
  color: #a7a8a9;
  clip-path: inset(0 1.2ch 0 0);
  animation: ${animateDots} 1s steps(4) infinite;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};
`;
