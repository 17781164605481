import { FC, PropsWithChildren, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStreaming } from '../context/StreamingProvider';
import Header from './Header';
import {
  ScLayout,
  ScLayoutContent,
  ScLayoutContentWrapper,
  ScBackgroundHeader,
} from './Layout/styles';
import { streamingSelectors } from '../store';
import Background from './Layout/Background';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = PropsWithChildren<{}>;

const Layout: FC<Props> = ({ children }) => {
  const [canPlay, setCanPlay] = useState<boolean>(false);

  const { connectionStatus, sendMessage } = useStreaming();
  const terminal = useSelector(streamingSelectors.getTerminal);
  const screen = useSelector(streamingSelectors.getScreen);
  const slots = useSelector(streamingSelectors.getSlots);

  useEffect(() => {
    setCanPlay((terminal && terminal.status === 'vacant') || screen === 'standby');
  }, [terminal, screen]);

  const standbyView = localStorage.getItem('standby') || 'video';

  const handleLogout = useCallback(() => {
    sendMessage({
      type: 'logout',
      data: {
        customerId: terminal?.customerId,
      },
    });
  }, [sendMessage, terminal]);

  const globalSlot = slots?.global;

  if (canPlay) {
    // @todo: show header on the top with logout button
    return (
      <Background
        standbyView={standbyView}
        header={
          <ScBackgroundHeader
            connectionStatus={connectionStatus}
            modelName={globalSlot?.modelName}
            handleLogout={handleLogout}
            isDark={standbyView === 'video'}
          />
        }
      />
    );
  }

  return (
    <ScLayout>
      <ScLayoutContentWrapper>
        <Header
          connectionStatus={connectionStatus}
          modelName={globalSlot?.modelName}
          handleLogout={handleLogout}
        />
        <ScLayoutContent>{children}</ScLayoutContent>
      </ScLayoutContentWrapper>
    </ScLayout>
  );
};

export default Layout;
